img {
    max-height: 100%;
    max-width: 100%;
}

.container {
    width: auto;
    max-width: 800px;
    margin: 1em auto;
    text-align: center;
}

.label {
    display: block;
    margin-bottom: .5em;
    width: 15em;
    margin: 0 auto;
}

.label input {
    width: 100%;
}

.arrowButton {
    all: initial;
    text-align: center;
    height: 4em;
    width: 4em;
    transition: all .5s ease-out;
}

.arrowButton:focus-visible {
    outline: auto;
}

.arrowButton:disabled {
    opacity: .3;
    filter: grayscale();
}
