@use "sass:map";
@use "sass:math";
@use "../../variables.scss" as variables;

$phi: 1.6180339887498948482; // golden ratio

$speech-bubble-unit: 1em;
$speech-bubble: (
    unit: $speech-bubble-unit,
    border-width: 0.125 * $speech-bubble-unit,
    border-radius: 2 * $speech-bubble-unit,
    pointer-border-radius: 4 * $speech-bubble-unit
);

/// Given a circle of radius r, and a square with side length r with one corner
/// in the circle center, find the side length of a square that if placed with
/// one corner just touching the corner of the square with side length r would
/// just touch the edge of the circle with its opposite corner.
/// Optionally, introduce a gap between the circle and the square.
///
/// Solve for x:
///
///   ___r___       ___r___
///  |\      |     |\      |
///  | \     |     | r     |
///  |  \    |     |  \    |
/// r|   a   |    r|   .___|
///  |    \  |     |   |\  |
///  |     \ |     |   |a-r|x
///  |______\|     |___|__\|
///                      x
@function circle-square-complement($radius, $gap: 0) {
    @if not math.compatible($radius, $gap) {
        @error 'The units of $radius (#{$radius}) and $gap (#{$gap}) must be compatible';
    }

    // convert gap to ensure gap and radius are of the same unit
    $gap: 0 * $radius + $gap;

    // strip unit for easier arithmetic
    $unit: 0 * $radius + 1;
    $gap: $gap / $unit;
    $radius: $radius / $unit;

    // Pythagorean theorem: a^2 = b^2 + c^2
    // b = c = r; a^2 = 2(r^2)
    $a: math.sqrt(2 * math.pow($radius, 2));

    // Pythagorean theorem again: (a-r)^2 = 2(x^2)
    $x: math.sqrt(math.pow($a - $radius - $gap, 2) / 2);

    @return $x * $unit;
}

.scene {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    overflow: hidden;
}

.avatar {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.avatar.hasInteraction {
    box-sizing: content-box; // place the border outside the box
    border: solid 1px rgba(218, 165, 32, 0.1);
    border-radius: 2%;
    animation: glow 2s infinite alternate;
}

@keyframes glow {
    from {
        box-shadow: 0 0 4px -4px rgba(218, 165, 32, 0.1);
    }
    to {
        box-shadow: 0 0 4px 4px rgba(218, 165, 32, 0.4);
    }
}

.avatar:not(.hasInteraction) {
    pointer-events: none;
}

.speechBubble {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.5em;
    border-radius: map.get($speech-bubble, border-radius);
    border: map.get($speech-bubble, border-width) solid rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.6);
    min-width: map.get($speech-bubble, border-radius) + map.get($speech-bubble, pointer-border-radius);
    min-height: map.get($speech-bubble, border-radius) + map.get($speech-bubble, pointer-border-radius);
    white-space: pre-line;
}

.speechBubble span {
    display: inline-block;
}

.speechBubble.left {
    &:not(.up) {
        border-bottom-right-radius: map.get($speech-bubble, pointer-border-radius);
    }
    &.up {
        border-top-right-radius: map.get($speech-bubble, pointer-border-radius);
    }
}

.speechBubble.right {
    &:not(.up) {
        border-bottom-left-radius: map.get($speech-bubble, pointer-border-radius);
    }
    &.up {
        border-top-left-radius: map.get($speech-bubble, pointer-border-radius);
    }
}

.speechBubble.up {
    min-width: 2 * map.get($speech-bubble, pointer-border-radius);
}

.speechBubble.down {
    min-width: 2 * map.get($speech-bubble, pointer-border-radius);
    border-top-right-radius: map.get($speech-bubble, pointer-border-radius);
    border-top-left-radius: map.get($speech-bubble, pointer-border-radius);
}

.speechBubble.center {
    min-width: 2 * map.get($speech-bubble, pointer-border-radius);
    min-height: 2 * map.get($speech-bubble, pointer-border-radius);
    border-radius: map.get($speech-bubble, pointer-border-radius);
}

.speechBubble:not(.center)::after {
    position: absolute;
    content: " ";
    border: map.get($speech-bubble, border-width) solid rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    $base: circle-square-complement(
        map.get($speech-bubble, pointer-border-radius),
        $gap: map.get($speech-bubble, border-width)
    );
    height: $base * $phi;
    width: $base;
}

.speechBubble:not(.center):not(.up)::after {
    $base: circle-square-complement(
        map.get($speech-bubble, pointer-border-radius),
        $gap: map.get($speech-bubble, border-width)
    );
    transform: translateY($base * ($phi - 1));
}

.speechBubble:not(.center).up::after {
    $base: circle-square-complement(
        map.get($speech-bubble, pointer-border-radius),
        $gap: map.get($speech-bubble, border-width)
    );
    transform: translateY(-$base * ($phi - 1));
}

.speechBubble.left:not(.up)::after {
    right: 0;
    bottom: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}
.speechBubble.left.up::after {
    right: 0;
    top: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

.speechBubble.right:not(.up)::after {
    left: 0;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
}

.speechBubble.right.up::after {
    left: 0;
    top: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}
