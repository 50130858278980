@use "../../variables.scss" as variables;

.gameFrame {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    overflow: hidden;
}

.leftOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 10%;
    margin-left: 1%;
    // background: rgba(255,255,255,0.2);
}

.rightOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10%;
    margin-right: 1%;
    // background: rgba(255,255,255,0.2);
}

@media screen and (max-width: variables.$width-breakpoint) {
    @media screen and(min-aspect-ratio: variables.$aspect-ratio) {
        .leftOverlay {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            width: 10%;
            // background: rgba(255,255,255,0.2);
        }

        .rightOverlay {
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            width: 10%;
            // background: rgba(255,255,255,0.2);
            .statsNamePlate {
                margin-left: -60%;
            }
        }
    }

    @media screen and(max-aspect-ratio: variables.$aspect-ratio) {
        .leftOverlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 10%;
            // background: rgba(255,255,255,0.2);
        }

        .rightOverlay {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 10%;
            .statsNamePlate {
                margin-left: -140%;
            }
            // width: 10%;
            // background: rgba(255,255,255,0.2);
        }
    }
}
