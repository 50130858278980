.rowWrapper {
    display: flex;
    justify-content: center;
}

.iconWrapper {
    width: fit-content;
}

.tileWrapper {
    margin-top: 10px;
    cursor: pointer;
}