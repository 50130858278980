@use '../variables.scss' as variables;

.avatar {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.container {
    width: 100vw;
    height: 68.47vw; /* 100/68.47 = 1.4606 */
    background: #f6d635;
    max-height: 100vh;
    max-width: 146.06vh; /* sqrt(32/15) = 1.4606 */
    margin: auto;
    position: absolute;
    top: 0; bottom: 0; /* vertical center */
    left: 0; right: 0; /* horizontal center */
}

.gridContainer {
    display: grid;
    width: 100vw;
    height: 100vh;
    // background-color: green;
    grid-template-areas: "center";

    .container {
        grid-area: center;
    }

    .gridTop {
        display: none;
        grid-area: top;
        // background-color: red;
    }
    .gridBottom {
        display: none;
        grid-area: bottom;
        // background-color: pink;
    }
    .gridLeft {
        display: none;
        grid-area: left;
        // background-color: brown;
    }
    .gridRight {
        display: none;
        grid-area: right;
        // background-color: purple;
    }
}

@media screen and (max-width: variables.$width-breakpoint) {
    @media screen and(min-aspect-ratio: variables.$aspect-ratio) {
        .gridContainer {
            grid-template-columns: variables.$grid-column-width auto variables.$grid-column-width;
            grid-template-areas: "left center right";
            .gridLeft {
                display: block;
            }
            .gridRight {
                display: block;
            }
        }
    }

    @media screen and(max-aspect-ratio: variables.$aspect-ratio) {
        .gridContainer {
            grid-template-rows: variables.$grid-row-height auto variables.$grid-row-height;
            grid-template-areas:
                "top"
                "center"
                "bottom";

            .gridTop {
                display: block;
            }
            .gridBottom {
                display: block;
            }
        }
    }
}