.createFlashcard {
    display: grid;
    grid-template-areas: "left icon title right" "left content content right";
    grid-template-columns: 1fr auto 3fr 1fr;
    justify-items: center;
    align-items: center;
}

.createCardTitle {
    grid-row: title-start;
    grid-column: left / span right;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    grid-area: content;
}

.cardsRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
    column-gap: 5px;
}

.cardBox {
    display: flex;
    position: relative;
    border: 1px solid black;
    padding: 10px 10px;
    align-items: stretch;
}

.cardBox:hover {
    cursor: pointer;
    box-shadow: 1px 1px 1px 1px #888888;
}

.cardBox.active {
    border: 1px solid red;
}

.cardBox:not(.latest) div {
    position: absolute;
    background-color: black;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.cardBox.saved {}

.cardBox.editing div {
    position: absolute;
    background-color: black;
    top: 25%;
    left: 25%;
    right: 25%;
    bottom: 25%;
}

.submitBox {
    width: fit-content;
    border: 1px solid black;
    margin: 10px;
    padding: 0 20px;
}

.isActive {
    cursor: pointer;
}

.notActive {}

.editCard {
    padding: 5px;
    border: 1px solid black;
    background-color: #f6d635;
}

.editCardTop {
    display: flex;
}

p:last-of-type {
    margin-left: auto;
}


.ScrollBox {
    display: flex;
    flex-wrap: nowrap;
    height: 400px;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: inset 0 -10px 5px -10px black;
}

.finished {
    box-shadow: none;
    justify-content: flex-start;
}

.scrollList {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    row-gap: 10px;    
    margin: 0 auto;
    padding: 5px 15px;
}

.scrollRow {
    display: flex;
    column-gap: 5px;
    align-items: start;
}

.currentCardRow {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    column-gap: 5px;
    flex-direction: row;
}

.textArea {
    width: 100%;
    height: 100%;
    margin: 10px 0;
}

.cardText {
    width: 100%;
    height: 100%;
    margin: 10px 0;
    word-wrap: normal;
}

// Transition classes

.rotateX {
    transform: rotateX(90deg);
    transition: transform 0.5s;
}

.rotateBackX {
    transform: rotateX(0deg);
    transition: transform 0.6s;
}

.rotateY {
    transform: rotateY(90deg);
    transition: transform 0.5s;
}

.rotateBackY {
    transform: rotateY(0deg);
    transition: transform 0.6s;
}

.moveRight {
    position: relative;
    transition: translate 0.8s;
    translate: 50% -10%;
    z-index: 1;
}

.moveLeft {
    position: relative;
    transition: translate 0.8s, rotate 0.7s ease-out;
    translate: -50% -10%;
    rotate: 0 1 0 180deg;
    z-index: 0;
    backface-visibility: hidden;
}

@keyframes flipInY {
    from {
        transform: translateY(150%);
    }
    to {
        transform: translateY(0);
    }
}

.flipInY {
    animation: flipInY 0.8s;
}

@keyframes flipInBackY {
    from {
        rotate: 0 1 0 180deg;
        translate: -100% 0%;
    }
    25% {
        rotate: 0 1 0 180deg;
        translate: -100% 0%;
    }
    to {
        transform: rotateY(0deg);
        translate: 0% 0%;
    }
}

.flipInY .flipInFrontY {
    position: relative;
    z-index: 1;
}

.flipInY .flipInBackY {
    position: relative;
    animation: flipInBackY 0.8s;
    z-index: 0;
    backface-visibility: hidden;
}

$flipInSpeed: 1s;


@keyframes flipInX {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

.flipInX {
    animation: flipInX $flipInSpeed ease-out;
}

@keyframes flipInBackX {
    from {
        rotate: 0 1 0 180deg;
        translate: -105% 0%;
    }
    55% {
        translate: -105% 0%;
    }
    65% {
        translate: -50% 0%;
    }
    85% {
        rotate: 0 1 0 180deg;
    }
    90% {
        translate: 0% 0%;
    }
    to {
        rotate: 0 1 0 0deg;
        translate: 0% 0%;
    }
}

.flipInX .flipInFrontX {
    position: relative;
    z-index: 1;
}

.flipInX .flipInBackX {
    position: relative;
    animation: flipInBackX $flipInSpeed ease-out;
    z-index: 0;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    65% {
        opacity: 0;
    }
    66% {
        // opacity: 1;
    }
    90% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.flipInX .flipInBackX *{
    animation: fadein $flipInSpeed;
}