@use "../../variables.scss" as variables;

.mouseCatcher {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
}

.flashCard {
    overflow-x: hidden; /* this gives us space to hide the feedback window */
    position: fixed;
    top: 1em;
    bottom: 1em;
    left: 1em;
    right: 1em;
    padding: 1.5em;
    border-radius: 1em;
    background: #f6d635;
    border: 8px solid #f67535;
    border-radius: 5px;
}

@media screen and (min-width: variables.$width-breakpoint) {
    .flashCard {
        position: absolute;
        top: 5%;
        left: 10%;
        right: 10%;
        bottom: 5%;
    }
}

.scene {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 110px auto;
    flex: 1 1 auto;
    // margin-top: 1em;
}

.sceneContent {
    flex: 3;
    padding: 1em;
    text-align: center;
    grid-column: 2;
    grid-row: 2;
}

.gameObject {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.startButton {
    top: 20%;
    left: 30%;
    width: 40%;
    height: 40%;
    background-image: url('~/public/assets/bare-default/logo.png');
}

.goldenArrow {
    top: 65%;
    left: 45%;    
    width: 10%;
    height: 10%;
    background-image: url('~/public/assets/bare-default/golden-arrow.png');
}

.recommended {
    position: absolute;
    top: 80%;
    left: 15%;
    height: 7%;
    width: 70%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
    gap: 5px;
    overflow: hidden;
}

.recommendedButton {
    flex: 0 1 15%;
    background-color: white;
    cursor: pointer;

    border: solid 1px #c06010;

    > div {
        position: relative;
        padding: 22px 8px 5px 29px;
        white-space: nowrap;
        overflow: hidden;
    }
    > div::after {
        content: " ";
        position: absolute;
        top: 5px;
        left: 5px;
        height: 25px;
        width: 25px;
        background-image: url('../gameMode/elevert_flashcard_icon.png');
        background-size: contain;
        background-repeat: no-repeat;
    }
}
