@use "../../variables.scss" as variables;

.iconStyle {
    max-height: 16vh;
    max-width: 16vh;
    border: solid 2px orange;
    border-radius: 4px;
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 5px;
    &.withText {
        img {
            max-width: 75%;
            max-height: 75%;
        }
    }
    &.large {
        width: 100px;
        height: 100px;
    }
}

.hover:hover {
    &.hover_icon:hover {
        padding: 2px;
    }
}

.clickable {
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }
}
@media screen and (max-width: variables.$width-breakpoint) {

    @media screen and (min-aspect-ratio: variables.$aspect-ratio) {
        .iconStyle {
            width: 50px;
            height: 50px;
            &.withText {
                img {
                    max-width: 65%;
                    max-height: 65%;                
                }
            }
            &.large {
                width: 75px;
                height: 75px;
            }
        }
    }

@media screen and(max-aspect-ratio: variables.$aspect-ratio) {
    .iconStyle {
        border-width: 1px;
        width: 30px;
        height: 30px;
        padding: 2px;
        &.withText {
            img {
                max-width: 65%;
                max-height: 65%;            
            }
            .iconText {
                font-size: 0.5rem;
                height: fit-content;
            
            }
        }
        &.large {
            width: 40px;
            height: 40px;
        }
    }
}
}