.tooltip {
    position: relative;
    // display: inline-block;
    // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */

    /* Tooltip text */
    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        top: 100%;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
        &.tooltiptext_right {
            right: 0;
        }
        &.tooltiptext_left {
            left: 0;
        }
    }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}