@use "../../variables.scss" as variables;

.reportBug {
    display: none;
    // overflow-x: hidden;
    position: absolute;
    top: 2em;
    left: 100%;
    width: 30em;
    max-width: 100%;
    z-index: 11;
    transition: all 0.4s;
    user-select: none;
    text-align: center;
    background: #f6d635;
    border: 8px solid #f67535;
    &.active {
        display: block;
        position: fixed;
        top: 15vh;
        bottom: 15vh;
        left: 10vw;
        right: 10vw;
        width: auto;
        height: auto;
    }

    .fieldHeadline {
        text-align: left;
        padding-left: 10%;
        padding-bottom: 10px;
        padding-top: 5px;
        font-weight: bold;
        background: darkgray;
        border-bottom: 1px solid orange;
        
    }

    .fieldText {
        text-align: left;
        padding: 5px;
        padding-left: 10%;
        margin-top: 5px;
    }

    .fieldRow {
        border-bottom: 1px solid orange;
        padding: 2px;
        .fieldLabel {
            text-align: left;
            float: left;
            padding-left: 10%;
            padding-right: 5%;
            background: #f6d635;
            width: 10%;
        }
        .fieldInputDiv {
            // text-align: left;
            // width: 100%;
            .fieldInput {
                text-align: left;
                // float: left;
                // padding-left: 10%;
                // margin-left: 10%;
                width: 80%;
                padding-left: 2%;
            }
        }
    }
    .fieldTextArea {
        width: 80%;
        height: 50%;
        margin-top: 5px;
        padding: 10px;
        // padding-left: 2%;
    }

    .arrow {
        max-width: 50px;
        margin-top: 10px;
        cursor: pointer;
        &.sending {
            opacity: 50%;
            cursor: default;
        }
    }
}
.closeButton {
    margin: 6px;
    position: absolute;
    max-width: 20px;
    left: 95%;
    cursor: pointer;
}

@media screen and (min-aspect-ratio: variables.$aspect-ratio) and (max-width: variables.$width-breakpoint) {  
    .reportBug {
        &.active {
            top: 10vh;
            bottom: 10vh;
        }
    } 
} 
@media screen and (max-aspect-ratio: variables.$aspect-ratio) {    
.reportBug {
    .arrow {
        // max-width: 25%;
        margin-top: 30px;
    }
    .closeButton {
        left: 85%;
    }
}
}
