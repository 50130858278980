.rewardNumber {
    position: absolute;
    top: -10%;
    left: 50%;
}
@keyframes rewardNumberDrop {
    from {
        top: -10%;
    }
    10% {
        top: -10%;
    }
    90% {
        top: 40%;
    }
    to {
        top: 40%;
    }
}

.rewardNumberDrop {
    animation: rewardNumberDrop 2s ease-in-out 0s 1 forwards running;
}

@keyframes moveLeft {
    from {
        transform: translate(0%, 0%);
    }
    to {
        transform: translate(-140%, 0%);
    }
}

@keyframes moveRight {
    from {
        transform: translate(0%, 0%);
    }
    to {
        transform: translate(120%, 0%);
    }
}

@keyframes chosenDisappear {
    from {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
    }
}

@keyframes glow {
    from {
        box-shadow: 0 0 4px -4px rgba(218, 165, 32, 0.1);
    }
    to {
        box-shadow: 0 0 4px 4px rgba(218, 165, 32, 0.4);
    }
}

.moveLeft {
    position: absolute;
    animation: moveLeft 5s forwards; /* Apply animation */
    left: 40%;
}

.moveRight {
    position: absolute;
    animation: moveRight 5s forwards; /* Apply animation */
    left: 40%;

}

.rewardModule {
    .rewardOptions {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        grid-gap: 1em;
        .rewardDiv {
            
        }
        .reward {
            position: absolute;
            align-self: center;
            transform: translate(-50%);
        }
        
        .reward.chosenLeft {            
            position: absolute;
            // left: 50%;
            // top: 50%;
            transform: translate(110%, 0%);
            z-index: 15;
        }

        .reward.chosenRight {            
            position: absolute;
            // left: 50%;
            // top: 50%;
            transform: translate(-110%, 0%);
            z-index: 15;
        }

        .reward.notChosen {
            opacity: 0;
            transition: opacity 2s ease-in-out; /* Transition effect */
        }

        .reward.chosen {
            pointer-events: none;
            animation: chosenDisappear 4s forwards; /* Apply animation */
        }

        .glow:hover {
            box-shadow: 0px 0px 5px 5px rgba(246, 117, 53, 0.5);
            transition: all 0.5s ease;
            cursor: pointer;
        }
        
        .glow:not(:hover) {
            animation: glow 2s infinite alternate;
            cursor: pointer;
        }        
    }
}