
@keyframes exitStageLeft {
    from {
        transform: translateY(100vw);
    }
    60% {
        opacity: 1;
    }
    to {
        transform: translateY(0);
        opacity: 0;
    }
}

.exitStageLeft {
    animation: exitStageLeft 2s;
    z-index: 1000;
    height: 50px;
    position: absolute;
    top: 40%;
    left: 3%;
}