.startScreenImage {
    overflow-x: hidden; /* this gives us space to hide the feedback window */
    border-radius: 1em;
    border: 8px solid #f67535;
    border-radius: 5px;
    max-width: 80vw;
    margin-bottom: 30px;
}

.logo {
    max-width: 35vw;
    max-height: 15vh;
    width: 75px;
    margin-top:1vh;
}

.bottomRow {
    position: absolute;
    display: block;
    margin-left: auto;
    margin-right: auto;
    left: 0; right: 0;
    top: 69vh;
}

.goldenArrow {
    max-width: 20vw;
    max-height: 20vh;
    width: 70px;
    
}

.container {
    height:68.47vw; 
    max-width: 73vh; 
    max-height: 50vh;
    margin: auto;
    position: absolute;
    top: 0; bottom: 15%; /* vertical almost center */
    left: 0; right: 0; /* horizontal center */
    border: 8px solid #f67535;
    border-radius: 5px;
}