@use "../../../variables.scss" as variables;

.scores {
    position: relative;
    top: -100%;
    opacity: 0;
    transition:
        top 0.4s,
        opacity 0.4s;
}

.scores.show {
    top: 0;
    opacity: 1;
}

.statsNamePlate {
    position: relative;
    margin-top: 20px;
    // margin-left: -60%;
    width: 200px;
    height: 50px;
    cursor: pointer;
    border-radius: 4px;
    z-index: -1;

    .bannerText {
        position: absolute;
        top: 5px;
        right: 0;
        left: 0;
    }
}

.statsNamePlate.right {
    float: right;
    margin-bottom: 10px;
}

@media screen and (max-width: variables.$width-breakpoint) {
    @media screen and(min-aspect-ratio: variables.$aspect-ratio) {

        .scores {
            z-index: -2;
        }


        .hover:hover {
            &.hover_banner {
                padding: 0px;
                box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);

                div {
                    font-size: large;
                    bottom: 45%;
                }
            }
        }
    }

    @media screen and(max-aspect-ratio: variables.$aspect-ratio) {

        .statsNamePlate {
            position: relative;
            margin-top: 10px;
            width: 130px;
            height: 30px;
            z-index: inherit;

            .bannerText {
                top: 0;
            }
        }
    }
}