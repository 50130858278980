@use "../../variables.scss" as variables;

.continuePlaying {
    // overflow-x: hidden;
    display: block;
    position: absolute;
    top: 2em;
    left: 100%;
    width: 30em;
    max-width: 100%;
    z-index: 0;
    transition: all .4s;
    user-select: none;
    text-align: center;
}

.continuePlaying.active {
    position:fixed;
    top: 15em;
    bottom: 15em;
    left: 15em;
    right: 15em;
    width:auto;
    height:auto;
    transform: translateX(0%);
}

.continuePlayingContent {
    background: #f6d635; 
    border: 8px solid #f67535;
    padding: 30px;
    width: 50%;
}

.feedback {
    // overflow-x: hidden;
    display: block;
    position: absolute;
    top: 2em;
    left: 100%;
    width: 30em;
    max-width: 100%;
    z-index: 0;
    transition: all .4s;
    user-select: none;
    text-align: center;
}

.feedback.active {
    
    position:fixed;
    top: 1em;
    bottom: 1em;
    left: 1em;
    right: 1em;
    width:auto;
    height:auto;
    // left:0;
    // width: 100%;
    // height: 100%;
    transform: translateX(0%);
}

.feedbackBanner {
    /* position the banner on the red ribbon above the paper area of the background image */
    /* position: absolute;
    left: 0;
    right: 0;
    top: 7%;
    transform: translateY(-50%); */

    /* set text properties */
    /* font-size: 1.25em;
    color: white; */
}

.feedbackBannerCorrect {
    font-family: 'Architects Daughter', cursive;
    position: absolute;
    border-top: 50px solid #b6f635;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    height: 0;
    /* width: 100px; */

    margin-top: -20px;
    width: 50%;
    left: 25%;
}

.feedbackBannerIncorrect {
    font-family: 'Architects Daughter', cursive;
    position: absolute;
    border-top: 50px solid #f63555;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    height: 0;
    /* width: 100px; */

    margin-top: -20px;
    width: 50%;
    left: 25%;
}

.feedbackBannerText {
    margin-top: -38px;
}

.feedbackBody {
    /* position the body inside the paper area of the background image */
    /* position: absolute;
    top: 17.5%;
    left: 10%;
    right: 10%;
    bottom: 15%;
    overflow-y: auto; */
    /* background-color: #f6d635; */
    overflow-x: scroll;
    border: 8px solid #f67535;
    border-radius: 5px;

}

.correct {
    background-color: #D9EAD3;
    border-color: #b6f635;
}

.incorrect {
    background-color: #F4CCCC;
    border-color: #f63555;
}

.fireworks {
    // background-image: url(./firework-7790_256.gif);
    // background-repeat: no-repeat;
    position: absolute; 
    inset: 0; 
    pointer-events: none;
}

.plusStart {
    position: absolute;
    transform: translateY(0vh);

}

@keyframes ascend {
    from {
        transform: translateY(70vh);
    }
    70% {
        opacity: 1;
    }
    to {
        transform: translateY(0vh);
        opacity: 0;
    }
}

.plusAscend {
    position: absolute;
    animation-name: ascend;
    animation-duration: 600ms;
    // animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@media screen and (min-width: variables.$width-breakpoint) {
    
    
    // .feedback {
    //     display: block;
    //         position: fixed;
    //         width: 100%;
    //         height: 100%;
    //         max-width: 100%;
    //         z-index: 11;
    //         transition: all .4s;
    //         user-select: none;
    //         text-align: center;
    // }

    .feedback.active {
        // left: 50%;
        // padding: 0em 2%;
        // transform: translateX(-50%);
        position: fixed;
        top: 5%;
        left: 10%;
        right: 10%;
        bottom: 5%;
    }

    .feedbackBody {
        display: grid;
            grid-template-columns: 50% 50%;
            grid-template-areas:
                'left right'
                'bottom bottom';
            height: 100%;
    }

    .desktop {
        display: block;
    }

    .horizontalMobile {
        display: none;
    }

    .verticleMobile {
        display: none;
    }
}

.reportProblemButtonBox {
    display: inline-block;
    border: 2px solid orange;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    margin: 5px;
}

@media screen and (max-width: variables.$width-breakpoint) {

    // Horizontal Mobile
    @media screen and (min-aspect-ratio: variables.$aspect-ratio) {       
        .continuePlaying.active {
            top: 3em;
            bottom: 3em;
            left: 1em;
            right: 1em;
            width:auto;
            height:auto;
            transform: translateX(0%);
        }

        .continuePlayingContent {
            width: 90%;
        }
        // .feedback {
        //     display: block;
        //     position: fixed;
        //     width: 100%;
        //     height: 100%;
        //     max-width: 100%;
        //     z-index: 11;
        //     transition: all .4s;
        //     user-select: none;
        //     text-align: center;
        // }

        // .feedback.active {
        //     left: 50%;
        //     padding: 0em 2%;
        //     transform: translateX(-50%);
        // }

        .feedbackBody {
            display: grid;
            grid-template-columns: 50% 50%;
            grid-template-areas:
                'left right'
                'bottom bottom';
            height: 100%;
        }
        .horizontalMobile {
            display: block;
        }

        .verticleMobile {
            display: none;
        }
    }

    // Verticle Mobile
    @media screen and (max-aspect-ratio: variables.$aspect-ratio) { 

        .continuePlaying.active {
            top: 3em;
            bottom: 3em;
            left: 1em;
            right: 1em;
            width:auto;
            height:auto;
            transform: translateX(0%);
        }

        .continuePlayingContent {
            width: 90%;
        }

        // .feedback {
        //     display: block;
        //     position: fixed;
        //     top: 2em;
        //     left: 100%;
        //     width: 100%;
        //     max-width: 100%;
        //     min-height: 75%;
        //     z-index: 11;
        //     transition: all .4s;
        //     user-select: none;
        //     text-align: center;
        // }

        // .feedback.active {
        //     left: 50%;
        //     padding: 0em 2%;
        //     transform: translateX(-50%);
        // }
        .feedbackBody {
            // min-height: 50vh;
            height: 100%;
        }
        .horizontalMobile {
            display: none;
        }

        .verticleMobile {
            display: block;
        }
    }
    .desktop {
        display: none;
    }
}