@use "../../variables.scss" as variables;

.difficultyModal {
    overflow-x: hidden; /* this gives us space to hide the feedback window */
    position: fixed;
    // top: 1em;
    // bottom: 1em;
    // left: 1em;
    // right: 1em;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    // min-width: 35em;
    padding: 1.5em;
    border-radius: 1em;
    background: #f6d635;
    border: 8px solid #f67535;
    border-radius: 5px;
    display: grid;
}

.difficultyHeader {
    text-align: center;
    font-family: 'Architects Daughter', cursive;
}

.difficultyImgBox {
    cursor: pointer;
    text-align: center;
    align-items: center;
    margin: 10px;
}

.difficultyImg {
    width: 75%;
    height: auto;
    display: block;
    margin: 0 auto;
}

// @media screen and (max-width: variables.$width-breakpoint) {
    @media screen and (min-aspect-ratio: variables.$aspect-ratio) {
        .difficultyModal {
            grid-template-rows: 1fr 2fr 1fr;
            grid-template-columns: auto auto auto;
        }
        .difficultyHeader {
            grid-row: 1;
            grid-column: 1/4;
        }

        .difficultyImgBox {
            grid-row: 2;
        }
    }

    @media screen and (max-aspect-ratio: variables.$aspect-ratio) {
        .difficultyModal {
            grid-template-columns: auto;
            grid-template-rows: auto auto auto auto auto;
        }
    }
// }
