.feedback {
    // overflow-x: hidden;
    display: block;
    position: absolute;
    top: 2em;
    left: 100%;
    width: 30em;
    max-width: 100%;
    z-index: 0;
    transition: all .4s;
    user-select: none;
    text-align: center;
}

.feedback.active {
    position:fixed;
    top: 1em;
    bottom: 1em;
    left: 1em;
    right: 1em;
    width:auto;
    height:auto;
    transform: translateX(0%);
}

.feedbackBody {
    background-color: #f6d635;
    overflow-x: scroll;
    border: 8px solid #f67535;
    border-radius: 5px;

}

.feedbackBody.active {
    
}

.feedback.active {
    position: fixed;
    top: 5%;
    left: 10%;
    right: 10%;
    bottom: 5%;
}

.feedbackBody {
    display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            'top top'
            'leftTop rightTop'
            'leftBottom rightBottom'
            'bottom bottom';
        height: 100%;
}

.leftArrow {
    transform: rotate(180deg);
    max-height: 10vh;
    cursor: pointer;
}

.rightArrow {
    max-height: 10vh;
    cursor: pointer;
}