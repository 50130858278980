@use "../../variables.scss" as variables;

.mouseCatcher {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
}

.challenge {
    overflow-x: hidden; /* this gives us space to hide the feedback window */
    position: fixed;
    top: 1em;
    bottom: 1em;
    left: 1em;
    right: 1em;
    padding: 1.5em;
    border-radius: 1em;
    background: #f6d635;
    border: 8px solid #f67535;
    border-radius: 5px;
}

@media screen and (min-width: variables.$width-breakpoint) {
    .challenge {
        position: absolute;
        top: 5%;
        left: 10%;
        right: 10%;
        bottom: 5%;
    }
}

.progress {
    position: relative;
    width: 100%;
    max-width: 25em;
    height: 1.25em;
    margin: 0 auto;
}

.progress > img {
    position: absolute;
    height: 100%;
    width: 100%;
    transition: clip-path 0.5s;
}

.scene {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 110px auto;
    flex: 1 1 auto;
    // margin-top: 1em;
}

.sceneContent {
    flex: 3;
    padding: 1em;
    text-align: center;
    grid-column: 2;
    grid-row: 2;
}

.avatarContainer {
    position: relative;
    flex: 1;
    grid-column: 2;
    grid-row: 1;
}

.avatar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.continueButton {
    max-width: 10%;
}

.buttonRow {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    .buttonBox {
        text-align: center;
        left: auto;
        right: auto;
        display: inline-block;
        border: 2px solid orange;
        border-radius: 5px;
        padding: 5px;
        margin-right: 20px;
        cursor: pointer;
        width: 110px;
        .buttonImage {
            // margin-right: 20px;
            max-width: 20px;
            cursor: pointer;
            opacity: 100%;
            &.sending {
                cursor: default;
                opacity: 50%;
            }
        }
        .reportSentImg {
            max-width: 20px;
        }
    }
}

@media screen and (max-width: variables.$width-breakpoint) {
    .continueButton {
        max-width: 17%;
    }
    @media screen and (min-aspect-ratio: variables.$aspect-ratio) {
        // .challenge {
        //     top: 0;
        //     bottom: 0;
        // }
    }

    @media screen and (max-aspect-ratio: variables.$aspect-ratio) {
        .buttonRow {
            .reportProblemButton {
                margin-right: 0;
                margin-bottom: 30px;
            }
            .reportSentImg {
                margin-right: 0;
                margin-bottom: 30px;
            }
        }
        // .challenge {
        //     position: fixed;
        //     // background-color: green;
        //     height: 100%;
        //     // max-height: calc(calc(68vw) + calc(variables.$grid-row-height));
        //     top: 0
        // }
        .nextButton {
            max-width: 20%;
        }
    }
}
