@use "../../variables.scss" as variables;

.container {
    width: 100vw;
    height: 68.47vw; /* 100/68.47 = 1.4606 */
    // background: #f6d635;
    max-height: 100vh;
    max-width: 146.06vh; /* sqrt(32/15) = 1.4606 */
    margin: auto;
    position: absolute;
    top: 0; bottom: 0; /* vertical center */
    left: 0; right: 0; /* horizontal center */
}