.statModal {
    position: fixed; // Changed from static positioning
    top: 50%; // Position halfway down the viewport
    left: 50%; // Position halfway across the viewport
    transform: translate(-50%, -50%); // Offset the modal so its center is at the viewport's center
    width: 300px;
    height: 400px;
    max-height: 95%;
    max-width: 95%;
    background-color: #f6d635;
    border: 4px solid #f67535;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.body {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 150px repeat(4, auto);
    flex: 1 1 auto;
    margin-top: 1em;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    max-width: 20px;
    // color: #333;
    cursor: pointer;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.stat {
    font-size: 18px;
    color: #333;
}